<template>
  <div
    class="col-sm mb-2 mb-sm-0 d-flex justify-content-between align-items-center flex-sm-row"
  >
    <div
      class="d-flex justify-content-center justify-content-sm-start align-items-center flex-column flex-sm-row"
    >
      <span class="me-2">Afficher :</span>
      <select v-model="size" @change="refreshSize()">
        <option v-if="allData.count >= 5" value="5">5</option>
        <option v-if="allData.count >= 10" value="10">10</option>
        <option v-if="allData.count >= 30" value="30">30</option>
        <option v-if="allData.count >= 50" value="50">50</option>
        <option v-if="allData.count >= 75" value="75">75</option>
        <option v-if="allData.count >= 100" value="100">100</option>
      </select>
      <span class="mx-2">sur</span>
      <span id="datatableWithPaginationInfoTotalQty">{{ allData.count }}</span>
    </div>
    <div
      v-if="allData"
      class="d-flex justify-content-center justify-content-sm-end align-items-center flex-sm-row flex-column"
    >
      <span class="d-flex justify-content-between align-item-center p-2">
        Page
        <input
          v-model="currentPage"
          type="number"
          class="mx-2"
          style="width: 55px"
          @change="
            () => {
              if (currentPage > pageCount) {
                $emit('selectedPage', { currentPage: pageCount });
                return;
              }
              $emit('selectedPage', { currentPage: currentPage });
            }
          "
        />
        sur
        {{ pageCount }}
      </span>
      <div
        class="d-flex justify-content-center justify-content-sm-end align-items-center flex-sm-row"
      >
        <button
          v-if="allData.previous && currentPage > 1"
          class="btn btn-light btn-active-light-primary me-2"
          @click.prevent="previousPage()"
        >
          Page précédente
        </button>
        <button
          v-if="allData.next && currentPage < pageCount"
          class="btn btn-primary ml-3"
          @click.prevent="nextPage()"
        >
          Page suivante
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableFooter",
  props: {
    // eslint-disable-next-line vue/require-prop-types
    allData: { default: null },
    // eslint-disable-next-line vue/require-prop-types
    defaultSize: { default: null },
    // eslint-disable-next-line vue/require-prop-types
    page: { default: 1 },
    // eslint-disable-next-line vue/require-prop-types
    hasQuery: { default: true },
  },
  data() {
    return {
      size: null,
      pageCount: 0,
      currentPage: 1,
    };
  },
  watch: {
    page(newPage) {
      this.currentPage = newPage;
    },
  },
  mounted() {
    if (this.hasQuery) {
      const query = { ...this.$route.query };
      if (!query.size && !query.page) {
        query.size = 30;
        query.page = 1;
      }
      this.size = query.size;
      this.currentPage = query.page;
    }
    this.refreshSize("mounted");
  },
  methods: {
    refreshSize(param) {
      if (this.size) {
        if (!param) {
          this.currentPage = 1;
        }
        this.$emit("selectedSize", { sizeSelected: this.size });
        this.$emit("selectedPage", { currentPage: this.currentPage });
        this.calculPages(this.allData.count);
        this.addQuery(this.size, this.currentPage);
      }
    },
    calculPages(dataCount) {
      if (!this.size) {
        if (this.defaultSize) {
          this.size = this.defaultSize;
        } else if (!this.defaultSize) {
          this.size = 30;
        }
        this.pageCount = Math.ceil(dataCount / this.size);
        if (this.pageCount <= 0) {
          this.pageCount = 1;
        }
      } else {
        this.pageCount = Math.ceil(dataCount / this.size);
        if (this.pageCount <= 0 || dataCount <= this.size) {
          this.pageCount = 1;
        }
      }
    },
    addQuery(size, page) {
      const query = { ...this.$route.query };
      query.size = size;
      query.page = page;
      this.$router.replace({ query });
    },
    previousPage() {
      this.$emit("previousPage", { previousPage: true });
      this.currentPage--;
      this.$emit("selectedPage", { currentPage: this.currentPage });
      this.addQuery(this.size, this.currentPage);
    },
    nextPage() {
      this.$emit("nextPage", { nextPage: true });
      this.currentPage++;
      this.$emit("selectedPage", { currentPage: this.currentPage });
      this.addQuery(this.size, this.currentPage);
    },
  },
};
</script>