<template>
  <div class="content fs-6 d-flex flex-column flex-column-fluid">
    <div class="toolbar">
      <div class="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
        <div
          class="d-flex flex-column align-items-start justify-content-center flex-wrap me-2"
        >
          <h1 class="text-dark fw-bolder my-1 fs-2">Contacts Mairies</h1>
        </div>
        <div class="d-flex align-items-center flex-nowrap text-nowrap py-1">
          <span v-if="citiesContacts" class="badge badge-primary">{{
            citiesContacts.count
          }}</span>
        </div>
      </div>
    </div>
    <div class="post fs-6 d-flex flex-column-fluid">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header border-0 pt-6">
            <div class="card-title"></div>
            <div class="card-toolbar">
              <div class="d-flex justify-content-end">
                <div class="me-3 d-flex align-items-center">
                  <input
                    v-model="scrollValue"
                    type="range"
                    min="0"
                    :max="maxScroll"
                    class="form-range"
                    @input="scrollTable"
                  />
                </div>
                <div class="dropdown me-3">
                  <button
                    id="dropdownMenuButton1"
                    type="button"
                    class="btn btn-light-primary"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="false"
                    aria-expanded="false"
                  >
                    <span class="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="5"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    Masquer des colonnes
                  </button>
                  <div
                    class="dropdown-menu menu menu-sub menu-sub-dropdown w-md-300px"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div class="px-7 py-5">
                      <div class="fs-5 text-dark fw-bolder">Colonnes</div>
                    </div>
                    <div class="separator border-gray-200"></div>
                    <form class="px-7 py-5">
                      <div
                        v-for="(column, index) in columnsLabels"
                        :key="index"
                        class="mb-3"
                      >
                        <input
                          :id="`${column}_id`"
                          type="checkbox"
                          class="form-check-input"
                          checked
                          @change="handleCheckboxChange(column)"
                        />
                        <label
                          :for="`${column}_id`"
                          class="form-label fw-bold ps-2"
                        >
                          {{ getColumnTitle(column) }}
                        </label>
                      </div>
                    </form>
                  </div>
                </div>
                <CitiesContactsFilters class="me-3" />

                <span
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#addContactSelectModal"
                >
                  <span class="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="11.364"
                        y="20.364"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(-90 11.364 20.364)"
                        fill="black"
                      />
                      <rect
                        x="4.36396"
                        y="11.364"
                        width="16"
                        height="2"
                        rx="1"
                        fill="black"
                      />
                    </svg>
                  </span>
                  Ajouter un contact
                </span>
              </div>
            </div>
          </div>

          <!-- Loader -->
          <Loader :data="citiesContacts" />

          <div v-if="citiesContacts">
            <div v-if="citiesContacts.count > 0">
              <div class="card-body p-0">
                <div
                  ref="tableResponsiveCitiesContacts"
                  class="table-responsive"
                  @scroll="onTableScroll"
                >
                  <table
                    v-if="citiesContacts.results"
                    class="table table-flush table-responsive align-middle table-row-bordered table-row-solid gy-4"
                  >
                    <div class="h-700px overflow-scroll">
                      <thead
                        style="
                          position: sticky;
                          top: 0;
                          z-index: 1;
                          background-color: white;
                        "
                      >
                        <tr
                          class="text-start fw-bolder fs-6 text-uppercase gs-0"
                        >
                          <th
                            v-for="(column, index) in columns"
                            :key="index"
                            :title="getColumnTitle(column)"
                            class="min-w-125px ps-10"
                          >
                            {{ getColumnTitle(column) }}
                          </th>
                        </tr>
                      </thead>
                      <tbody class="fw-bold text-gray-700">
                        <tr
                          v-for="contact in citiesContacts.results"
                          :key="contact.id"
                          style="cursor: pointer"
                        >
                          <template v-for="column in columns">
                            <td
                              v-if="columns.includes(column)"
                              :key="column"
                              class="ps-9"
                            >
                              <router-link
                                :to="'/cities/' + contact.city.id"
                                class="text-black"
                              >
                                <span
                                  v-if="column === 'owners' && contact[column]"
                                >
                                  {{
                                    contact[column].length == 0 ? "Aucun" : ""
                                  }}
                                  <span
                                    v-if="
                                      column === 'owners' &&
                                      contact[column] &&
                                      contact[column].length > 0
                                    "
                                  >
                                    <template v-for="owner in contact[column]">
                                      {{ getContactOwner(owner) }}
                                    </template>
                                  </span>
                                </span>

                                <span
                                  v-else-if="
                                    contact[column] && column === 'city'
                                  "
                                  >{{ contact[column].libcom_2014 }}</span
                                >
                                <span
                                  v-else-if="
                                    contact[column] && column === 'position'
                                  "
                                  >{{
                                    getContactPosition(contact[column])
                                  }}</span
                                >
                                <span
                                  v-else-if="
                                    contact[column] &&
                                    column === 'relation_quality'
                                  "
                                  class="ms-2 badge bg-light-warning text-black"
                                  >{{ contact[column] }}
                                  <i class="bi bi-star-fill text-warning h6"></i
                                ></span>

                                <span v-else-if="contact[column]">{{
                                  contact[column]
                                }}</span>
                                <span v-else class="text-black-50"
                                  >Non renseigné</span
                                >
                              </router-link>
                            </td>
                          </template>
                        </tr>
                      </tbody>
                    </div>
                  </table>
                </div>
              </div>
              <div v-if="citiesContacts" class="card-footer">
                <div
                  class="row justify-content-center justify-content-sm-between align-items-sm-center"
                >
                  <TableFooter
                    v-if="citiesContacts"
                    :all-data="citiesContacts"
                    :default-size="30"
                    :page="filter.page"
                    :has-query="true"
                    @selectedSize="getSizeSelected"
                    @selectedPage="getPageSelected"
                    @nextPage="showNextCities(citiesContacts.next)"
                    @previousPage="showPreviousCities(citiesContacts.previous)"
                    @change="getAllCities"
                  />
                </div>
              </div>
            </div>
            <div v-else class="card-body ms-1">
              <div class="alert alert-secondary">
                <div class="d-flex flex-column">
                  <span class="text-dark"
                    >Aucun contact mairie enregistrée</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    id="addContactSelectModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="addContactSelectModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header">
          <h3 class="fw-boldest text-dark fs-1 mb-0">Ajouter un contact</h3>
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-2x">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </div>
        <!-- Modal form -->
        <form @submit.prevent="createContact()">
          <!-- Modal body -->
          <div class="modal-body px-lg-12">
            <div class="row mb-5">
              <div class="col-md-6 fv-row">
                <label class="required fs-5 fw-bold mb-2">Nom</label>
                <input
                  v-model="formContact.first_name"
                  type="text"
                  class="form-control form-control"
                />
              </div>
              <div class="col-md-6 fv-row">
                <label class="required fs-5 fw-bold mb-2">Prénom</label>
                <input
                  v-model="formContact.last_name"
                  type="text"
                  class="form-control form-control"
                />
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-md-6 fv-row">
                <label class="fs-5 fw-bold mb-2">Email</label>
                <input
                  v-model="formContact.email"
                  class="form-control form-control"
                />
              </div>
              <div class="col-md-6 fv-row">
                <label class="fs-5 fw-bold mb-2">Numéro de téléphone</label>
                <input
                  v-model="formContact.phone_number"
                  v-mask="'00 00 00 00 00'"
                  type="text"
                  class="form-control form-control"
                />
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-md-6 fv-row">
                <label class="fs-5 fw-bold mb-2">Poste</label>
                <select
                  v-if="cityPositions"
                  v-model="formContact.position"
                  class="form-select form-select form-select-lg"
                >
                  <option
                    v-for="position in cityPositions"
                    :key="position.id"
                    :value="position.id"
                  >
                    {{ position.title }}
                  </option>
                </select>
              </div>
              <div class="col-md-6 fv-row">
                <label class="fs-5 fw-bold mb-2">Personne en contact</label>
                <v-select
                  v-if="usersArray"
                  v-model="ownersSelected"
                  multiple
                  :options="usersArray"
                  class="form-control form-control form-select-lg"
                />
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-md-12 fv-row">
                <label class="fs-5 fw-bold mb-2 required">Ville</label>
                <v-select
                  v-if="cities"
                  v-model="citySelected"
                  class="form-control"
                  :options="citiesArray"
                  @search="search"
                />
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light me-3"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              :disabled="
                formContact.last_name !== '' &&
                formContact.first_name !== '' &&
                citySelected !== '' &&
                citySelected !== null &&
                citySelected.length !== 0
                  ? false
                  : true
              "
            >
              Ajouter
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";
import TableFooter from "@/components/TableFooter";
import CitiesContactsFilters from "@/components/cities/CitiesContactsFilters.vue";
import store from "@/store";

export default {
  name: "CitiesContacts",
  components: {
    Loader,
    TableFooter,
    CitiesContactsFilters,
  },
  data() {
    return {
      filter: {
        order: "",
        size: "",
        defaultSize: 30,
        page: "",
      },
      columns: [
        "first_name",
        "last_name",
        "city",
        "email",
        "phone_number",
        "position",
        "relation_quality",
        "owners",
      ],
      columnsLabels: [
        "first_name",
        "last_name",
        "city",
        "email",
        "phone_number",
        "position",
        "relation_quality",
        "owners",
      ],
      citiesContactsData: "",
      citiesFilter: {
        order: "",
        size: "",
        defaultSize: 100,
        page: "",
      },
      scrollValue: 0,
      maxScroll: 100,
      formContact: {
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        position: "",
        city_id: "",
        owners: "",
      },
      usersArray: [],
      ownersSelected: [],
      citiesArray: [],
      citySelected: [],
    };
  },
  computed: {
    ...mapGetters(["citiesContacts"]),
    ...mapGetters(["cities"]),
    ...mapGetters(["cityPositions"]),
    ...mapGetters(["users"]),
  },
  methods: {
    ...mapActions(["getCitiesContacts"]),
    ...mapActions(["getCities"]),
    ...mapActions(["getUsers"]),
    ...mapActions(["getCityPositions"]),
    ...mapActions(["nextPaginate"]),
    ...mapActions(["previousPaginate"]),
    ...mapActions(["postCityContact"]),
    ...mapActions(["searchCity"]),

    getAllCitiesContacts() {
      this.getCitiesContacts(this.filter);
      this.citiesContactsData = this.citiesContacts;
    },
    async getAllUsers() {
      await this.getUsers();
      if (this.users && this.users.results) {
        this.usersArray = this.users.results.map((user) => {
          return { ...user, label: user.first_name + " " + user.last_name };
        });
      }
    },
    async getAllCities() {
      await this.getCities(this.citiesFilter);
    },
    async getAllCityPositions() {
      await this.getCityPositions();
    },
    // Paginations
    async getPageSelected(res) {
      this.filter.page = res.currentPage;
    },
    async getSizeSelected(res) {
      this.filter.size = res.sizeSelected;
    },
    async showNextCities(url) {
      this.citiesContactsData = await this.nextPaginate(url);
      store.commit("citiesContacts", {
        ...this.citiesContactsData,
      });
    },
    async showPreviousCities(url) {
      this.citiesContactsData = await this.previousPaginate(url);
      store.commit("citiesContacts", {
        ...this.citiesContactsData,
      });
    },

    handleCheckboxChange(column) {
      const index = this.columns.indexOf(column);
      const labelIndex = this.columnsLabels.indexOf(column);

      if (index !== -1) {
        this.columns.splice(index, 1);
      } else if (labelIndex !== -1) {
        this.columns.splice(labelIndex, 0, column);
      }
    },
    getColumnTitle(column) {
      const columnTitles = {
        first_name: "Prénom",
        last_name: "Nom",
        city: "Mairie",
        email: "E-mail",
        phone_number: "Numéro de téléphone",
        position: "Poste",
        relation_quality: "Qualité de la relation",
        owners: "Propriétaires",
      };
      return columnTitles[column];
    },
    getContactPosition(id) {
      if (this.cityPositions) {
        let position = null;
        for (let key in this.cityPositions) {
          if (this.cityPositions[key].id === id) {
            position = this.cityPositions[key];
          }
        }
        return position.title;
      }
    },
    getContactOwner(id) {
      if (this.users && this.users.results) {
        const owner = this.users.results.find((item) => item.id === id);
        if (owner) {
          return owner.first_name + " " + owner.last_name + " ";
        } else {
          return "Non trouvée";
        }
      }
    },
    scrollTable() {
      const tableContainer = this.$refs.tableResponsiveCitiesContacts;
      if (tableContainer) {
        const scrollPercentage = this.scrollValue / this.maxScroll;
        const maxScrollAmount =
          tableContainer.scrollWidth - tableContainer.clientWidth;
        const scrollAmount = maxScrollAmount * scrollPercentage;
        tableContainer.scrollLeft = scrollAmount;
      }
    },
    onTableScroll() {
      const tableContainer = this.$refs.tableResponsiveCitiesContacts;
      if (tableContainer) {
        const maxScrollAmount =
          tableContainer.scrollWidth - tableContainer.clientWidth;
        if (maxScrollAmount > 0) {
          const scrollPercentage = tableContainer.scrollLeft / maxScrollAmount;
          this.scrollValue = scrollPercentage * this.maxScroll;
        }
      }
    },
    async createContact() {
      this.formContact.city_id = this.citySelected.id;
      this.formContact.owners = this.ownersSelected.map((object) => object.id);
      await this.postCityContact(this.formContact);
      this.getAllCitiesContacts();
      this.formContact = {
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        position: "",
        city_id: "",
        owners: "",
        rating: "",
      };
      this.ownersSelected = [];
      this.citySelected = "";
      this.getAllCities();
    },
    async search(search) {
      if (search.length) {
        let array = [];
        await this.searchCity(search);
        this.cities.results.forEach((element) => {
          array.push({
            label: element.libcom_2014 ? element.libcom_2014 : "",
            id: element.id,
          });
        });
        this.citiesArray = array;
      }
    },
  },
  // eslint-disable-next-line vue/order-in-components
  mounted() {
    const query = { ...this.$route.query };
    if (query.page && query.size) {
      this.filter.page = query.page;
      this.filter.size = query.size;
    }
    this.getAllCitiesContacts();
    this.getAllCities();
    this.getAllCityPositions();
    this.getAllUsers();
  },
};
</script>
