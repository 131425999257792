<template>
  <div class="dropdown">
    <button
      id="dropdownMenuButton1"
      type="button"
      class="btn btn-light-primary"
      data-bs-toggle="dropdown"
      data-bs-auto-close="false"
      aria-expanded="false"
    >
      <span class="svg-icon svg-icon-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
            fill="black"
          />
        </svg>
      </span>
      Filtrer
    </button>
    <div
      class="dropdown-menu menu menu-sub menu-sub-dropdown w-250px w-md-300px"
      aria-labelledby="dropdownMenuButton1"
    >
      <div class="d-flex align-item-center justify-content-between px-7 py-5">
        <div class="fs-5 text-dark fw-bolder">Options</div>
      </div>
      <div class="separator border-gray-200"></div>
      <form class="px-7 py-5" @submit.prevent="getAllCitiesContacts()">
        <div class="mb-10 d-flex row">
          <div class="mb-3 col-12">
            <label class="form-label fw-bold">Mairie :</label>
            <select
              v-if="cities"
              v-model="filter.city"
              class="form-select form-select-solid form-select-lg"
            >
              <option
                v-for="city in cities.results"
                :key="city.id"
                :value="city.id"
              >
                {{ city.libcom_2014 }}
              </option>
            </select>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-light-danger me-3"
            @click.prevent="resetFilters()"
          >
            Vider
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            data-kt-menu-dismiss="true"
          >
            Appliquer
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import store from "@/store";

export default {
  name: "CitiesContactsFilters",
  data() {
    return {
      filter: {
        size: "",
        defaultSize: 30,
        page: "",
        city: "",
      },
      cityFilter: {
        size: "",
        defaultSize: 100,
        page: "",
      },
    };
  },
  computed: {
    ...mapGetters(["cities"]),
  },
  mounted() {
    this.getAllCities();
  },
  methods: {
    ...mapActions(["getCities"]),
    ...mapActions(["getCitiesContacts"]),

    async getAllCities() {
      store.commit("cities", null);
      await this.getCities(this.filter);
    },
    getAllCitiesContacts() {
      this.getCitiesContacts(this.filter);
    },
    resetFilters() {
      this.filter = {
        size: "",
        defaultSize: 30,
        page: "",
        city: "",
      };
      this.getCitiesContacts(this.filter);
    },
  },
};
</script>
